












































































































import { Component, Prop } from 'vue-property-decorator'
import { documentsNamespace } from '@store/documents'
import { DocumentsActionTypes } from '@store/documents/Types'
import { IStatusDocument, IDocumentHistory } from '@store/documents/Interface'
import { mixins } from 'vue-class-component'
import Documents from '@/mixins/Documents'
import { userNamespace} from "@store/user";
import http from "@/http";

@Component({
  components: {
    'v-form-change': () => import('@/components/forms/FormChange.vue'),
    'v-ecp-icon': () => import('@/views/library/components/EcpIcon.vue'),
  },
})
export default class Unit extends mixins(Documents) {
  @documentsNamespace.State('documentStatus')
  private documentStatus!: IStatusDocument[]

  @documentsNamespace.State('documentHistory')
  private documentHistory!: IDocumentHistory[]

  @documentsNamespace.Action(DocumentsActionTypes.A_CHANGE_STATUS)
  private changeStatusDocument!: (params) => void

  @documentsNamespace.Action(DocumentsActionTypes.A_DOCUMENT_HISTORY)
  private getDocumentHistory!: (documentId) => Promise<void>

  @userNamespace.Getter('accessedDocumentStatusesForProject')
  private accessedStatuses!: { [key: number]: boolean }

  @Prop({ default: '' }) private doc

  @Prop(Boolean) private isOwner

  private showFormChange: boolean = false

  private showLastChangeModal: boolean = false

  private isChecked: boolean = false

  private changeLoading : boolean = false

  private input: HTMLElement = document.createElement('div')
  private inputSuffix: HTMLElement = document.createElement('div')

  mounted () {
    this.$nextTick(() => {
      if (this.$refs.elSelect) {
        this.input = (((this.$refs.elSelect as any)
          .$el as HTMLElement)
          .querySelector('input') as HTMLElement)
        this.inputSuffix = (((this.$refs.elSelect as any)
          .$el as HTMLElement)
          .querySelector('.el-input__suffix-inner') as HTMLElement)
      }
    })
  }

  private getFileIdsInTom(tom, file_ids:number[] = []){
    tom.content.forEach(doc => {
      if(doc.type === 'tom'){
        file_ids = this.getFileIdsInTom(doc, file_ids)
      } else {
        file_ids.push(doc.id)
      }
    })

    return file_ids;
  }

  private async download(doc) {
    this.$emit('processingDocument', true)
    if(doc.type === 'tom'){
      http.post('/documents/mass-download', { documents: this.getFileIdsInTom(doc) })
        .then(r => {
          this.massDownloadDocuments(r.data.link)
        })
        .catch(error => this.$errorNotify(error.response?.data?.message))
        .finally(() => this.$emit('processingDocument', false))
    } else {
      await this.downloadDocumentById(doc.id)
      this.$emit('processingDocument', false)
    }
  }

  private changeDocumentation() {
    this.showFormChange = true
  }

  private changeStatus(value, id) {
    const params = {
      document_ids: [id],
      status_id: value,
    }

    this.changeStatusDocument(params)
  }

  private handleCheckedDocumentChange(val?:boolean) {
    this.$emit('change', {
      id: this.doc.id,
      value: this.isChecked,
    })
  }

  private showLastChange (documentId) {
    this.changeLoading = true
    this.getDocumentHistory(documentId).finally(() => this.changeLoading = false)
    this.showLastChangeModal = true
  }

  // возвращает объект содержащий обработанные свойства принадлежащие статусу(название, цвет текста, цвет бэкграунда)
  private getStatusObject (statusId) {
    let result = this.documentStatus.filter(item => item.id === statusId)[0] ?? {
      id: null,
      label: "Без статуса",
      color: null
    }

    result = Object.assign(result, { style: {
      color: result?.color ? `rgb(${result.color})` : null,
      backgroundColor: result?.color ? `rgba(${result.color},0.1)` : null,
    }})

    return result
  }
}
